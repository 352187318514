export const dataBook = {
  1: [[{ imgUrl: "img/FriendsPlus/ListPage/1.jpg", excerciseNum: 0 }]],
  2: [[{ imgUrl: "img/FriendsPlus/ListPage/2.jpg", excerciseNum: 0 }]],
  3: [[{ imgUrl: "img/FriendsPlus/ListPage/3.jpg", excerciseNum: 0 }]],
  4: [
    [{ imgUrl: "img/FriendsPlus/Page48/1.jpg", excerciseNum: 0 }],
    [{ imgUrl: "img/FriendsPlus/Page48/2.jpg", excerciseNum: 1 }],
    [{ imgUrl: "img/FriendsPlus/Page48/3.jpg", excerciseNum: 2 }],
    [{ imgUrl: "img/FriendsPlus/Page48/4.jpg", excerciseNum: 0 }],
  ],
  5: [
    [{ imgUrl: "img/FriendsPlus/Page49/1.jpg", excerciseNum: 0 }],
    [{ imgUrl: "img/FriendsPlus/Page49/2.jpg", excerciseNum: 1 }],
    [{ imgUrl: "img/FriendsPlus/Page49/3.jpg", excerciseNum: 2 }],
    [{ imgUrl: "img/FriendsPlus/Page49/4.jpg", excerciseNum: 0 }],
  ],
  6: [
    [{ imgUrl: "img/FriendsPlus/Page50/1.jpg", excerciseNum: 0 }],
    [{ imgUrl: "img/FriendsPlus/Page50/2.jpg", excerciseNum: 1 }],
    [{ imgUrl: "img/FriendsPlus/Page50/3.jpg", excerciseNum: 2 }],
    [{ imgUrl: "img/FriendsPlus/Page50/4.jpg", excerciseNum: 3 }],
    [{ imgUrl: "img/FriendsPlus/Page50/5.jpg", excerciseNum: 0 }],
  ],
  7: [
    [{ imgUrl: "img/FriendsPlus/Page51/1.jpg", excerciseNum: 0 }],
    [{ imgUrl: "img/FriendsPlus/Page51/2.jpg", excerciseNum: 1 }],
    [{ imgUrl: "img/FriendsPlus/Page51/3.jpg", excerciseNum: 2 }],
    [{ imgUrl: "img/FriendsPlus/Page51/4.jpg", excerciseNum: 3 }],
    [{ imgUrl: "img/FriendsPlus/Page51/5.jpg", excerciseNum: 0 }],
  ],
  8: [
    [{ imgUrl: "img/FriendsPlus/Page52/1.jpg", excerciseNum: 0 }],
    [{ imgUrl: "img/FriendsPlus/Page52/2.jpg", excerciseNum: 1 }],
    [{ imgUrl: "img/FriendsPlus/Page52/3.jpg", excerciseNum: 2 }],
    [{ imgUrl: "img/FriendsPlus/Page52/4.jpg", excerciseNum: 3 }],
    [{ imgUrl: "img/FriendsPlus/Page52/5.jpg", excerciseNum: 0 }],
  ],
  9: [
    [{ imgUrl: "img/FriendsPlus/Page53/1.jpg", excerciseNum: 0 }],
    [{ imgUrl: "img/FriendsPlus/Page53/2.jpg", excerciseNum: 1 }],
    [{ imgUrl: "img/FriendsPlus/Page53/3.jpg", excerciseNum: 2 }],
    [{ imgUrl: "img/FriendsPlus/Page53/4.jpg", excerciseNum: 3 }],
    [{ imgUrl: "img/FriendsPlus/Page53/5.jpg", excerciseNum: 0 }],
  ],
  // 101: [[{ imgUrl: "img/FriendsPlus/Page101/1.jpg", excerciseNum: 0 }]],
  // 102: [[{ imgUrl: "img/FriendsPlus/Page102/1.jpg", excerciseNum: 0 }]],
  // 103: [[{ imgUrl: "img/FriendsPlus/Page103/1.jpg", excerciseNum: 0 }]],
  // 104: [[{ imgUrl: "img/FriendsPlus/ListPage/104.jpg", excerciseNum: 0 }]],
  // 105: [[{ imgUrl: "img/FriendsPlus/ListPage/105.jpg", excerciseNum: 0 }]],
};
