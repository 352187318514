import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 7",
    id: "WB5-2024-U7-P48-E1",
    exerciseKey: "img/FriendsPlus/Page48/Key/E1answerKey.jpg",
    audio: "",
    video: "",
    component: Circle_Write,
    // recorder: true,
    titleQuestion: [
      {
        num: "1",
        title: "Read and circle the words below. Then write.",
        color: "#203c8f",
      },
    ],
    styleContent: {
      width: "90%",
      marginLeft: 50,
    },
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        colorLineTo: "gray",
        newPointDot: true,
        path: true,
        notOverlapse: true,
        circlePadding: "5 1",
        margin: "5px 2px",
        circleStringArray: ["modelsalivescreambonesdinosaursroarscarydead"],
        boxMatch: [],
        answers: [
          "0_17-0_21",
          "0_22-0_30",
          "0_6-0_10",
          "0_40-0_43",
          "0_31-0_34",
          "0_35-0_39",
          "0_0-0_5",
          "0_11-0_16",
        ],
        initialValue: ["0_17-0_21"],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: { width: 150, paddingBottom: 10, textAlign: "center" },
        answers: [
          "bones",
          "dinosaurs",
          "alive",
          "dead",
          "roar",
          "scary",
          "models",
          "scream",
        ],
        initialValue: ["bones"],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",

          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a.| <br> b.| <br> c.|",
          "a.| <br> b.| <br> c.|",
          "a.| <br> b.| <br> c.|",
          "a.| <br> b.| <br> c.|",
          "a.| <br> b.| <br> c.|",
        ],
        answers: [],
        initialValue: [],
      },
      Layout: `
        <b>1.</b>&ensp;People and animals have these inside them. <input id=0></input><br>
        <b>2.</b>&ensp;These animals lived on Earth millions of years ago. <input id=1></input><br>
        <b>3.</b>&ensp;Everyone in your class is this. <input id=2></input><br>
        <b>4.</b>&ensp;All of the dinosaurs are this. <input id=3></input><br>
        <b>5.</b>&ensp;A loud noise that some animals make. <input id=4></input><br>
        <b>6.</b>&ensp;Something that makes you feel scared is this. <input id=5></input><br>
        <b>7.</b>&ensp;People make these to show you what something looks like. <input id=6></input><br>
        <b>8.</b>&ensp;You make this noise when you are scared.<input id=7></input><br>
        <div id='circle-container' style='width: 26cm; margin-top: -15px; position: relative'>
          <svg width="26cm" height="9.5cm" viewBox="0 0 982.67719 340.15749"">
            <g
              transform="matrix(0.99889921,0,0,1.0000017,0.73087881,-18.332736)">
              <a
                transform="matrix(1.0946249,0,0,3.1185948,-4.2108864,37.629997)">
                <path
                  style="fill:#000000;fill-opacity:0;stroke:#000000;stroke-width:1.00055;stroke-opacity:1"
                  d="M 27.805759,131.33857 C 76.72709,109.95908 119.53325,103.85065 119.53325,103.85065 l 74.4012,-2.79969 161.28751,37.92313 65.99284,5.09036 c 0,0 151.85996,54.46678 202.81968,45.30415 50.95973,-9.16264 32.61422,-6.10843 82.04515,-29.01502 49.43094,-22.90659 112.62099,-41.23186 163.07111,-47.84933 50.45013,-6.61746 61.66126,-29.015015 61.66126,-29.015015 0,0 19.36469,-16.289133 27.00865,-4.581318"
                  id="match-path"
                  transform="matrix(0.91355495,0,0,0.32065725,3.8468761,-12.066331)" />
              </a>
            </g>
            <image x="20" y="80" width="940" href="img/FriendsPlus/Page48/E1/1.jpg"/>
          </svg>
          <input id=0 type='boxMatch'/>
        </div>
        `,
    },
  },
  2: {
    unit: "Unit 7",
    id: "WB5-2024-U7-P48-E2",
    audio: "Audios/19-audio.mp3",
    video: "",

    exerciseKey: "img/FriendsPlus/Page48/Key/E2answerKey.png",
    component: T6,
    // maxLength: 5,
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "dash",
    },
    inputSize: 150,
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen. Write the word from 1. <span style='color: white'><headphone name='&nbsp 19' typeimg=sound src='Audios/19-tieude.mp3'></headphone></span>",
        color: "#203c8f",
        left: 50,
      },
    ],
    questionImage: [],
    // isHello: true,
    questions: [
      {
        title: `
				<div style='display:flex'>
          <div style='width:15cm'>
            <b><i>The school trip</i></b><br>
            <b>1.</b>&ensp;Where did Mark go? The <u style='color:gray; text-underline-offset: 5px'>&nbspdinosaur&nbsp</u> dinosaur museum.<br>
            <b>2.</b>&ensp;There were lots of # of dinosaurs.<br>
            <b>3.</b>&ensp;The dinosaurs’ # were interesting.<br>
            <b>4.</b>&ensp;It was fun, but they looked #.<br>
            <b>5.</b>&ensp;He learned that the last dinosaurs were # 65 million years ago.<br>
          </div>
            <img style='height:10cm; margin-top: -50px' src='img/FriendsPlus/Page48/E2/1.jpg'/>
        </div>
        
        `,
        answer: ["models", "roars", "scary", "alive"],
      },
    ],
  },
};

export default json;
