import "./index.css";
import p1 from "./1";
import p2 from "./2";
import p3 from "./3";
import p48 from "./48";
import p49 from "./49";
import p50 from "./50";
import p51 from "./51";
import p52 from "./52";
import p53 from "./53";
// import p98 from "./98";
// import p99 from "./99";
// import p100 from "./100";
// import p101 from "./101";
// import p102 from "./102";
// import p103 from "./103";

const pageQuestions = {
  1: p1,
  2: p2,
  3: p3,
  4: p48,
  5: p49,
  6: p50,
  7: p51,
  8: p52,
  9: p53,
  // 97: p97,
  // 98: p98,
  // 99: p99,
  // 100: p100,
  // 101: p101,
  // 102: p102,
  // 103: p103,
};

export default pageQuestions;
